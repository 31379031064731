/* Plugin looks for elements with class named "balance-text" */
.balance-text {
    text-wrap: balance;  /* Apply (proposed) CSS style */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  --nav-height: 0px;
  --controls-bottom-height: 12rem;
  height: 100%;
}

.hide-control {
  --controls-bottom-height: 0px;
}

nav {
  height: var(--nav-height);
}

#center-wrapper {
  height: calc(100% - var(--nav-height));
}

#controls-bottom {
  display: none;
}

#controls-left {
  display: none;
}

#center-wrapper {
  height: calc(100% - var(--nav-height) - var(--controls-bottom-height));
}

#center-wrapper.patient-presentation {
  height: 100%;
}

#controls-bottom {
  display: flex;
  height: var(--controls-bottom-height);
}

#controls-left {
  display: block;
}
